$font: 'Poppins', sans-serif;

$bg: #331654;
$section: #7141a8;
$section_three_color: #416ea8;
$section_four_color: #a241a8;
$section_two: #BD84FF;
$section_three: #C3B5FF;
$section_four: rgb(238,181,255);
$btn: white;
$btn-color: #BC30BA;
$bubble: rgb(209,54,204);